import { buildMediaQueries } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';

const PageNumbersStyle = createOwnUpStyle({
  padding: '20px 0'
});

const PageNumbers = createOwnUpComponent('nav', PageNumbersStyle);

const ListStyle = createOwnUpStyle({
  listStyleType: 'none',
  paddingInlineStart: 0
});

const List = createOwnUpComponent('ul', ListStyle);

const ListItemStyle = createOwnUpStyle({
  display: 'inline-block'
});

const ListItem = createOwnUpComponent('li', ListItemStyle);

const PageLinkStyle = createOwnUpStyle({
  backgroundColor: 'transparent',
  border: 'none',
  color: colors.BRAND,
  fontSize: 20,
  cursor: 'pointer',
  height: 48,
  width: 48,
  padding: '0 10px',
  ':focus': {
    outline: `2px solid ${colors.CLASSIC_BLUE}`
  }
});

const PageLink = createOwnUpComponent('button', PageLinkStyle);

// eslint-disable-next-line max-lines-per-function, abcsize/abcsize
export const Pagination = ({
  currentPage,
  numOfPages,
  scrollToTop,
  setCurrentPage,
  limit,
  center
}: {
  currentPage: number;
  numOfPages: number;
  scrollToTop: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  limit: number; // max page numbers to display in nav.
  center: number; // center point of page numbers in nav.
}) => {
  // determine the starting point of the nav.
  // 1. Check if current page is at or below the center point on the first batch of pages displayed in the nav.
  // If so, starting point will just be 1.
  // 2. Check if the current page is on the last batch of pages displayed in the nav and if it is above the center point of the nav.
  // If so, offset is the full length of the nav (indicated by `limit`).
  // 3. In all other cases, offset is half the length of the nav (indicated by `center`).
  let navStart;
  if (currentPage <= center) {
    navStart = 1;
  } else {
    navStart =
      currentPage > numOfPages - center ? numOfPages - limit + 1 : currentPage - center + 1;
  }
  const nums = [];

  // add page numbers to the nav from starting point until the limit is reached
  do {
    nums.push(navStart);
    navStart = navStart + 1;
  } while (nums.length < limit && navStart <= numOfPages);

  return (
    <PageNumbers aria-label={`review pages ${limit > 3 ? 'desktop' : 'mobile'}`}>
      <List>
        <ListItem>
          <PageLink
            onClick={() => {
              setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
              scrollToTop();
            }}
          >
            {'<'}
          </PageLink>
        </ListItem>
        {nums.map((number) => (
          <ListItem key={number}>
            <PageLink
              onClick={() => {
                setCurrentPage(number);
                scrollToTop();
              }}
              aria-current={currentPage === number && 'page'}
            >
              {number}
            </PageLink>
          </ListItem>
        ))}
        <ListItem>
          <PageLink
            onClick={() => {
              setCurrentPage(currentPage === numOfPages ? currentPage : currentPage + 1);
              scrollToTop();
            }}
          >
            {'>'}
          </PageLink>
        </ListItem>
      </List>
    </PageNumbers>
  );
};

const LargeWrapper = styled('div')`
  ${buildMediaQueries('smallAndDown', {
    display: 'none'
  })}
`;

const SmallWrapper = styled('div')`
  ${buildMediaQueries(['medium', 'large'], {
    display: 'none'
  })}
`;

export const ResponsivePagination = ({
  currentPage,
  numOfPages,
  scrollToTop,
  setCurrentPage
}: {
  currentPage: number;
  numOfPages: number;
  scrollToTop: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => (
  <>
    <LargeWrapper>
      <Pagination
        numOfPages={numOfPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        scrollToTop={scrollToTop}
        limit={9}
        center={5}
      />
    </LargeWrapper>
    <SmallWrapper>
      <Pagination
        numOfPages={numOfPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        scrollToTop={scrollToTop}
        limit={3}
        center={2}
      />
    </SmallWrapper>
  </>
);
