import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction } from 'react';
import EmptyStar from '../../../images/reviews/yotpo-star-empty.svg';
import FullStar from '../../../images/reviews/yotpo-star-full.svg';

const StarWrapperStyle = createOwnUpStyle({
  display: 'flex',
  unicodeBidi: 'bidi-override',
  direction: 'rtl',
  width: 220
});

const StarWrapper = createOwnUpComponent(OwnUpBox, StarWrapperStyle);

const StarStyle = createOwnUpStyle({
  backgroundColor: 'transparent',
  border: 'none',
  width: 30,
  height: 30,
  padding: 0,
  margin: 7,
  ':hover:before': {
    content: `url(${FullStar})`,
    position: 'absolute',
    width: 30,
    height: 30
  },
  ':hover~:before': {
    content: `url(${FullStar})`,
    position: 'absolute',
    width: 30,
    height: 30
  }
});

const Star = createOwnUpComponent('button', StarStyle);

// eslint-disable-next-line abcsize/abcsize
const selectStar = (score: number | undefined, position: number) => {
  if (score) {
    if (position === 1) {
      return score < 5 ? EmptyStar : FullStar;
    }
    if (position === 2) {
      return score < 4 ? EmptyStar : FullStar;
    }
    if (position === 3) {
      return score < 3 ? EmptyStar : FullStar;
    }
    if (position === 4) {
      return score < 2 ? EmptyStar : FullStar;
    }
    if (position === 5) {
      return score < 1 ? EmptyStar : FullStar;
    }
  } else {
    return EmptyStar;
  }
};

export const ReviewStars = ({
  score,
  setScore
}: {
  score: number | undefined;
  setScore: Dispatch<SetStateAction<number>>;
}) => (
  <StarWrapper>
    {[5, 4, 3, 2, 1].map((star, i) => {
      return (
        <Star key={i} type="button" onClick={() => setScore(star)}>
          <img alt={`rating star ${star}`} src={selectStar(score, i + 1)} />
        </Star>
      );
    })}
  </StarWrapper>
);
