import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { AnyStyledComponent } from 'styled-components';
import { ReviewForm } from '../review-form';
import { ReviewSuccessScreen } from '../review-success-screen';

export const ReviewFormContainer = ({
  handleClose
}: {
  handleClose: Dispatch<SetStateAction<boolean>>;
}) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [review, setReview] = useState<string | undefined>(undefined);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [title, setTitle] = useState<string | undefined>(undefined);

  const successRef = useRef<AnyStyledComponent>(null);

  return (
    <>
      {reviewSubmitted ? (
        <ReviewSuccessScreen handleClose={handleClose} content={review} ref={successRef} />
      ) : (
        <ReviewForm
          email={email}
          name={name}
          review={review}
          score={score}
          title={title}
          setEmail={setEmail}
          setName={setName}
          setReview={setReview}
          setReviewSubmitted={setReviewSubmitted}
          setScore={setScore}
          setTitle={setTitle}
          successRef={successRef}
        />
      )}
    </>
  );
};
