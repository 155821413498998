/* eslint-disable react/display-name */
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpIcon,
  OwnUpIconProps,
  OwnUpStyledIcon
} from '@rategravity/own-up-component-library';
import { CancelX } from '@rategravity/own-up-component-library/components/images/icons/svgs';
import React, { Dispatch, SetStateAction } from 'react';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { colors } from '../../../modules/colors';
import { CallToActionButton } from '../../button';
import { ResponsiveCatchphrase, ResponsivePrimaryText } from '../../typography';

const SuccessWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.WHITE,
  border: `1px solid ${colors.LIGHT_GREY}`,
  padding: 30,
  position: 'relative'
});

const SuccessWrapper = createOwnUpComponent('section', SuccessWrapperStyle);

const IconWrapper = createOwnUpComponent('button', {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  height: 24,
  width: 24,
  position: 'absolute',
  top: 30,
  right: 30
});

const TextWrapperStyle = createOwnUpStyle({
  padding: '10px 0'
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

const SocialButtonWrapperStyle = createOwnUpStyle({
  display: 'flex',
  padding: 20,
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

const SocialButtonWrapper = createOwnUpComponent(OwnUpBox, SocialButtonWrapperStyle);

const ButtonContentStyle = createOwnUpStyle({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const ButtonContent = createOwnUpComponent(OwnUpBox, ButtonContentStyle);

export const ReviewSuccessScreen = React.forwardRef(
  // eslint-disable-next-line max-lines-per-function
  (
    {
      content,
      handleClose
    }: {
      content: string | undefined;
      handleClose: Dispatch<SetStateAction<boolean>>;
    },
    ref
  ) => {
    const socialButtons: { name: OwnUpIconProps['icon']; link: string; label: string }[] = [
      {
        name: 'Twitter',
        link: `https://twitter.com/intent/tweet?url=https://www.ownup.com&app_key=WsnKQhTzMZui6KeFNNb5Zpx6LTUnyBzATK31jyWd&redirect=true&text=${content}&via=yotpo`,
        label: 'twitter'
      },
      {
        name: 'FacebookLogoNoCircle',
        link: `https://www.facebook.com/dialog/feed?app_id=226132034107547&display=popup&link=https://www.ownup.com`,
        label: 'facebook'
      },
      {
        name: 'LinkedIn',
        link: `https://www.linkedin.com/sharing/share-offsite/?url=https://yotpo.com/go?reference_name=linkedin_social_share&url=https://www.ownup.com&app_key=WsnKQhTzMZui6KeFNNb5Zpx6LTUnyBzATK31jyWd&redirect=true`,
        label: 'linkedin'
      }
    ];
    const mixpanel = useMixpanel();
    return (
      <SuccessWrapper aria-label="review-submitted-successfully">
        <IconWrapper
          aria-label="close review submitted successfully section"
          onClick={() => handleClose(false)}
        >
          <OwnUpStyledIcon
            style={{
              height: '24px',
              width: '24px',
              position: 'absolute',
              top: 0,
              right: 0,
              strokeWidth: 2
            }}
          >
            <CancelX color={colors.PRIMARY} />
          </OwnUpStyledIcon>
        </IconWrapper>
        <TextWrapper>
          <OwnUpIcon icon="Heart" color={colors.MINT} style={{ fontSize: 28 }} />
        </TextWrapper>
        <TextWrapper>
          <ResponsiveCatchphrase ref={ref} tabIndex={0}>
            Thank you for posting a review!
          </ResponsiveCatchphrase>
        </TextWrapper>
        <TextWrapper>
          <ResponsivePrimaryText>
            We value your input. Share your review so everyone else can enjoy it too.
          </ResponsivePrimaryText>
        </TextWrapper>
        <SocialButtonWrapper>
          {socialButtons.map(({ name, link, label }, i) => (
            <div key={i} style={{ padding: 10 }}>
              <a
                href={link}
                aria-label={`share your review on ${label}`}
                onClick={() => {
                  mixpanel.track('Reviews action', { action: `share-review-${label}` });
                }}
              >
                <CallToActionButton>
                  <ButtonContent>
                    <OwnUpIcon
                      icon={name}
                      color={colors.CLASSIC_BLUE}
                      style={{ fontSize: 24, paddingRight: 5, marginTop: -6 }}
                    />
                    <div style={{ paddingTop: 2 }}>SHARE</div>
                  </ButtonContent>
                </CallToActionButton>
              </a>
            </div>
          ))}
        </SocialButtonWrapper>
      </SuccessWrapper>
    );
  }
);
