import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';
import { AnyStyledComponent } from 'styled-components';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { colors } from '../../../modules/colors';
import { errorText, validateForm } from '../../../modules/review-utils';
import { CallToActionButton, WrapperButton } from '../../button';
import { Catchphrase, ErrorText, PrimaryText } from '../../typography';
import { ReviewStars } from './review-stars';

const FormWrapperStyle = createOwnUpStyle({
  padding: '20px 0 50px',
  borderTop: `1px solid ${colors.GREY_ALT}`,
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  margin: 'auto'
});

const FormWrapper = createOwnUpComponent('section', FormWrapperStyle);

const FormFieldWrapperStyle = createOwnUpStyle({
  paddingBottom: 15
});

const FormFieldWrapper = createOwnUpComponent(OwnUpBox, FormFieldWrapperStyle);

const AsteriskStyle = createOwnUpStyle({
  color: 'red',
  fontSize: 14,
  paddingRight: 5
});

const Asterisk = createOwnUpComponent(OwnUpText, AsteriskStyle);

const LabelStyle = createOwnUpStyle({
  display: 'flex',
  alignItems: 'baseline'
});

const Label = createOwnUpComponent('label', LabelStyle);

const NameEmailWrapperStyle = createOwnUpStyle({
  display: 'flex',
  width: '100%',
  variants: {
    smallAndDown: {
      flexDirection: 'column'
    }
  }
});

const NameEmailWrapper = createOwnUpComponent(OwnUpBox, NameEmailWrapperStyle);

const NameWrapperStyle = createOwnUpStyle({
  width: '50%',
  paddingRight: 20,
  variants: {
    smallAndDown: {
      width: '100%',
      paddingRight: 0
    }
  }
});

const NameWrapper = createOwnUpComponent(OwnUpBox, NameWrapperStyle);

const EmailWrapperStyle = createOwnUpStyle({
  width: '50%',
  paddingLeft: 20,
  variants: {
    smallAndDown: {
      width: '100%',
      paddingLeft: 0
    }
  }
});

const EmailWrapper = createOwnUpComponent(OwnUpBox, EmailWrapperStyle);

const ButtonWrapperStyle = createOwnUpStyle({
  paddingTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  variants: {
    smallAndDown: {
      display: 'block'
    }
  }
});

const ButtonWrapper = createOwnUpComponent(OwnUpBox, ButtonWrapperStyle);

// eslint-disable-next-line max-lines-per-function
export const ReviewForm = ({
  email,
  name,
  review,
  score,
  title,
  setEmail,
  setName,
  setReview,
  setReviewSubmitted,
  setScore,
  setTitle,
  successRef
}: {
  email: string | undefined;
  name: string | undefined;
  review: string | undefined;
  score: number | undefined;
  title: string | undefined;
  setEmail: Dispatch<SetStateAction<string | undefined>>;
  setName: Dispatch<SetStateAction<string | undefined>>;
  setReview: Dispatch<SetStateAction<string | undefined>>;
  setReviewSubmitted: Dispatch<SetStateAction<boolean>>;
  setScore: Dispatch<SetStateAction<number>>;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  successRef: RefObject<AnyStyledComponent>;
}) => {
  const [formErrors, setFormError] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState(false);

  const mixpanel = useMixpanel();

  const errorRef = useRef<AnyStyledComponent>(null);

  const validateField = (fieldName: string) =>
    formErrors.includes(fieldName) ? <ErrorText>{errorText[fieldName]}</ErrorText> : null;

  // eslint-disable-next-line max-lines-per-function
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = validateForm({ score, title, review, name, email });
    setFormError(errors);

    // set focus on the error text
    // give the ErrorText component time to re-mount before trying to set focus
    const focusToError = setTimeout(
      () => errorRef && errorRef.current && errorRef.current.base.focus(),
      200
    );

    // set focus on success message
    // give the ReviewSuccessScreen component time to mount before trying to set focus
    const focusToSuccess = setTimeout(
      () => successRef && successRef.current && successRef.current.base.focus(),
      500
    );

    if (errors.length < 1) {
      const url = `https://api.yotpo.com/v1/widget/reviews`;
      const options =
        process.env.GATSBY_PROD === 'true'
          ? {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              /* eslint-disable @typescript-eslint/naming-convention */
              body: JSON.stringify({
                appkey: process.env.YOTPO_APP_KEY,
                sku: 'yotpo_site_reviews',
                product_title: 'Own Up',
                product_url: 'https://www.ownup.com',
                domain: 'https://www.ownup.com',
                display_name: name,
                email,
                review_content: review,
                review_title: title,
                review_score: score
              })
              /* eslint-enable @typescript-eslint/naming-convention */
            }
          : {};
      await fetch(url, options).then((res) => {
        if (!res.ok) {
          mixpanel.track('Reviews action', { action: 'post-review', success: 'false' });
          setSubmitError(true);
          focusToError;
        } else {
          mixpanel.track('Reviews action', { action: 'post-review', success: 'true' });
          setReviewSubmitted(true);
          focusToSuccess;
        }
      });
    } else {
      mixpanel.track('Reviews action', { action: 'post-review', success: 'false' });
      focusToError;
    }
  };

  return (
    <FormWrapper aria-label="Form to write a review">
      <FormFieldWrapper>
        <Catchphrase>Write A Review</Catchphrase>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <PrimaryText>
          <Asterisk>*</Asterisk>Indicates a required field
        </PrimaryText>
      </FormFieldWrapper>
      <form onSubmit={handleSubmit}>
        <PrimaryText>
          <Label htmlFor="score">
            <Asterisk>*</Asterisk>Score:
            {formErrors.length > 0 ? validateField('score') : null}
          </Label>
        </PrimaryText>

        <div id="score" style={{ height: 50 }}>
          <ReviewStars score={score} setScore={setScore} />
        </div>

        <PrimaryText>
          <Label htmlFor="title">
            <Asterisk>*</Asterisk>Title:
            {validateField('title')}
          </Label>
        </PrimaryText>

        <FormFieldWrapper>
          <input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />
        </FormFieldWrapper>

        <PrimaryText>
          <Label htmlFor="review">
            <Asterisk>*</Asterisk>Review:
            {validateField('review')}
          </Label>
        </PrimaryText>

        <FormFieldWrapper>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            style={{ width: '100%' }}
          />
        </FormFieldWrapper>

        <NameEmailWrapper>
          <NameWrapper>
            <PrimaryText>
              <Label htmlFor="name">
                <Asterisk>*</Asterisk>Name:
                {validateField('name')}
              </Label>
            </PrimaryText>

            <FormFieldWrapper>
              <input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ width: '100%' }}
              />
            </FormFieldWrapper>
          </NameWrapper>

          <EmailWrapper>
            <PrimaryText>
              <Label htmlFor="email">
                <Asterisk>*</Asterisk>Email:
                {validateField('email')}
              </Label>
            </PrimaryText>

            <FormFieldWrapper>
              <input
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%' }}
              />
            </FormFieldWrapper>
          </EmailWrapper>
        </NameEmailWrapper>

        <ButtonWrapper>
          {formErrors.length > 0 || submitError ? (
            <ErrorText ref={errorRef} tabIndex={0}>
              {submitError
                ? 'There was an error submitting your review. Please try again.'
                : 'One or more of your answers does not meet the required criteria'}
            </ErrorText>
          ) : null}
          <WrapperButton onClick={handleSubmit}>
            <CallToActionButton>POST</CallToActionButton>
          </WrapperButton>
        </ButtonWrapper>
      </form>
    </FormWrapper>
  );
};
