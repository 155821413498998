import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { useState } from 'react';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { colors } from '../../../modules/colors';
import { formatDate, mapScoreToStarSVG, truncate } from '../../../modules/review-utils';

const ReviewWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '25px 0px'
});

const ReviewWrapper = createOwnUpComponent(OwnUpBox, ReviewWrapperStyle);

const NameStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_SEMIBOLD,
  display: 'block',
  textAlign: 'center',
  fontSize: 23
});

const Name = createOwnUpComponent(OwnUpText, NameStyle);

const SVGWrapperStyle = createOwnUpStyle({
  width: 200,
  padding: '5px 0px 10px'
});

const SVGWrapper = createOwnUpComponent(OwnUpBox, SVGWrapperStyle);

const DateStyle = createOwnUpStyle({
  display: 'block',
  textAlign: 'center'
});

const Date = createOwnUpComponent(OwnUpText, DateStyle);

const TitleStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  display: 'block',
  textAlign: 'center',
  fontSize: 25,
  padding: '10px 40px'
});

const Title = createOwnUpComponent(OwnUpText, TitleStyle);

const MainContentStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_REGULAR,
  display: 'block',
  textAlign: 'center',
  fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
  fontSize: 15,
  fontWeight: 100,
  lineHeight: 1.6,
  maxWidth: 340,
  padding: '0px 40px 30px 40px'
});

const MainContent = createOwnUpComponent(OwnUpText, MainContentStyle);

const TextControlStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontSize: 17,
  border: 'none',
  borderBottom: `2px solid ${colors.CLASSIC_BLUE}`,
  backgroundColor: 'transparent',
  color: colors.CLASSIC_BLUE,
  ':focus': {
    outline: `2px solid ${colors.CLASSIC_BLUE}`
  }
});

const TextControl = createOwnUpComponent('button', TextControlStyle);

export const Review = ({
  content,
  createdAt,
  name,
  score,
  title
}: {
  content: string;
  createdAt: string;
  name: string;
  score: number;
  title: string;
}) => {
  const [lessText, setLessText] = useState(true);
  const mixpanel = useMixpanel();

  return (
    <ReviewWrapper>
      <Name>{name}</Name>
      <SVGWrapper>
        <img alt={`${score} star rating`} src={mapScoreToStarSVG(score)} />
      </SVGWrapper>
      <Date>{formatDate(createdAt)}</Date>
      <Title>{title}</Title>
      <MainContent>{lessText ? truncate(content, 350) : content}</MainContent>
      {content.length > 350 ? (
        <TextControl
          onClick={() => {
            if (lessText) {
              mixpanel.track('Reviews action', { action: 'read-more-review' });
            }
            setLessText(!lessText);
          }}
        >
          {lessText ? 'Read More >' : 'Read Less >'}
        </TextControl>
      ) : null}
    </ReviewWrapper>
  );
};
