import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpColumns,
  ScreenSize,
  useScreenSize
} from '@rategravity/own-up-component-library';
import { OwnUpBox } from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { CallToActionButton } from '../../../components/button';
import { ResponsivePagination } from '../../../components/reviews/pagination';
import { Review } from '../../../components/reviews/review';
import { ReviewFormContainer } from '../../../components/reviews/review-form-container';
import { SectionLabel } from '../../../components/section-label';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import YotpoBottomLineSVG from '../../../images/reviews/powered-by-yotpo.svg';
import { colors } from '../../../modules/colors';
import { decodeHtml } from '../../../modules/review-utils';
import { WrapperButton } from '../../button';

const SectionWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  marginTop: 100,
  variants: {
    mediumAndDown: {
      marginTop: 50
    }
  }
});

const SectionWrapper = createOwnUpComponent('section', SectionWrapperStyle);

const BottomLineWrapperStyle = createOwnUpStyle({
  width: 164,
  height: 139,
  position: 'absolute',
  top: -100,
  right: 50,
  backgroundPosition: '50%',
  backgroundImage: `url(${YotpoBottomLineSVG})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  paddingTop: 20,
  variants: {
    mediumAndDown: {
      position: 'initial',
      maxWidth: 164,
      marginBottom: 50
    }
  }
});

const BottomLineWrapper = createOwnUpComponent(OwnUpBox, BottomLineWrapperStyle);

const BottomLineStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_SEMIBOLD,
  fontSize: 39,
  color: colors.BRAND,
  textAlign: 'center',
  paddingTop: 10
});

const BottomLine = createOwnUpComponent(OwnUpBox, BottomLineStyle);

const ButtonWrapperStyle = createOwnUpStyle({
  paddingBottom: 20
});

const ButtonWrapper = createOwnUpComponent(OwnUpBox, ButtonWrapperStyle);

const YotpoReviewsWrapperStyle = createOwnUpStyle({
  width: '100%',
  // Removing focus outlines is an a11y anti-pattern. Never do this to buttons or links.
  ':focus': {
    outline: 'none'
  }
});

const YotpoReviewsWrapper = createOwnUpComponent('section', YotpoReviewsWrapperStyle);

interface Review {
  score: number;
  content: string;
  title: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  created_at: string;
  user: {
    display_name: string;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
}

// eslint-disable-next-line max-lines-per-function
export const ReviewsWrapper = ({
  bottomLine,
  currentPage,
  reviews,
  setCurrentPage
}: {
  bottomLine: number;
  currentPage: number;
  reviews: Review[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const screenSize = useScreenSize();
  const [showForm, setShowForm] = useState(false);

  const reviewsRef = useRef<HTMLDivElement>(null);
  const scrollToRef = () => {
    if (reviewsRef && reviewsRef.current) {
      reviewsRef.current.focus();
      const bodyPos = document.body.getBoundingClientRect().top;
      const elPos = reviewsRef.current.getBoundingClientRect().top;
      // on smaller screen sizes, take height of fixed header into account
      const offset = elPos - (screenSize <= ScreenSize.medium ? 64 : 0) - bodyPos;
      window.scrollTo({ left: 0, top: offset, behavior: 'smooth' });
    }
  };
  const numberOfPages = Math.ceil(bottomLine / 9);
  const mixpanel = useMixpanel();

  return (
    <SectionWrapper aria-label="customer-reviews">
      <BottomLineWrapper>
        <BottomLine role="img" aria-label={`${bottomLine} Yotpo customer reviews`}>
          {bottomLine}
        </BottomLine>
      </BottomLineWrapper>
      <SectionLabel>Community Reviews</SectionLabel>
      <ButtonWrapper>
        <WrapperButton
          onClick={() => {
            mixpanel.track('Reviews action', { action: 'write-review' });
            setShowForm(!showForm);
          }}
        >
          <CallToActionButton>WRITE A REVIEW</CallToActionButton>
        </WrapperButton>
      </ButtonWrapper>
      {showForm ? <ReviewFormContainer handleClose={setShowForm} /> : null}
      <YotpoReviewsWrapper
        ref={reviewsRef}
        tabIndex={-1}
        aria-label={`reviews page ${currentPage}`}
      >
        <OwnUpColumns
          count={{
            small: 1,
            medium: 2,
            large: 3
          }}
        >
          {reviews.map(
            ({ score, content, title, created_at: createdAt, user: { display_name: name } }, i) => {
              const decodedContent = decodeHtml(content);
              const decodedTitle = decodeHtml(title);
              return (
                <Review
                  key={i}
                  {...{ score, content: decodedContent, title: decodedTitle, createdAt, name }}
                ></Review>
              );
            }
          )}
        </OwnUpColumns>
      </YotpoReviewsWrapper>
      <ResponsivePagination
        numOfPages={numberOfPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        scrollToTop={scrollToRef}
      />
    </SectionWrapper>
  );
};
